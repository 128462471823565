enum Colors {
  white__100 = '#f8f7ee',
  black__400 = '#191119',
  brown__300 = '#99642b',
  red__300 = '#c41e1e',
  red__400 = '#831616',
  orange__300 = '#e08814',
  orange__400 = '#9d5a02',
  yellow__300 = '#e0d512',
  yellow__400 = '#bbb218',
  green__300 = '#3caf3c',
  green__400 = '#3a7a3a',
  blue__300 = '#4b71bc',
  blue__400 = '#1d438d',
  purple__300 = '#8559a5',
  purple__400 = '#4d2768',
}
export default Colors;
