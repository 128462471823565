import * as React from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Typography, { Kind as TypographyKind } from '../components/Typography';

import Colors from '../components/Colors';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Sponsor from '../components/Sponsor';

const Sponsors = ({ data }) => (
  <Layout>
    <SEO title="Sponsors of Gays Making Pod" />
    <Typography
      kind={TypographyKind.LargestHeader}
      style={{
        color: Colors.green__300,
      }}
    >
      Show Sponsors
    </Typography>
    <p>
      The show currently is looking for sponsors. If you are interested in sponsoring the show,
      please reach out on social media.
    </p>
    {data.allSponsorsJson.edges.map(({ node }) => (
      <Sponsor
        key={node.id}
        name={node.name}
        website={node.website}
        description={node.description}
        src={node.src}
      />
    ))}
  </Layout>
);
Sponsors.propTypes = {
  data: PropTypes.object,
};
Sponsors.defaultProps = {
  data: {},
};

export const pageQuery = graphql`
  query {
    allSponsorsJson {
      edges {
        node {
          id
          name
          website
          description
          src {
            childImageSharp {
              gatsbyImageData(width: 64, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`;

export default Sponsors;
