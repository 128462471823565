import React from 'react';
import './Sponsor.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Typography, { Kind } from './Typography';

interface Props {
  name: string;
  description: string;
  website: string;
  src: any;
}

const Sponsor = ({
  name, description, website, src,
}: Props) => {
  const image = getImage(src);

  return (
    <div className="sponsor">
      {!!image && <GatsbyImage image={image} alt={`${name} logo`} className="logo" />}
      <Typography kind={Kind.EntryTitle}>
        <a href={website}>{name}</a>
      </Typography>
      <p>{description}</p>
    </div>
  );
};

export default Sponsor;
